.container {
  /* border: 1px solid #000; */
  width: 100%;
  /* max-width: 640px; */
}
.container h3 {
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.235;
  letter-spacing: 0px;
  color: var(--secondary-color);
  margin-bottom: 25px;
}
.usersContainer {
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* border: 1px solid #000; */
}
.container button {
  position: relative;
  outline: 0px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  width: 100%;
  border-radius: 16px;
  background: var(--gradient-bg);
  padding: 16px 24px;
  color: #ffffff;
  transition: 400ms all ease-in-out;
}
.container button:hover {
  opacity: 0.9;
}
