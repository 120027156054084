.container {
  -webkit-animation: anim 1200ms infinite linear;
  animation: anim 2000ms infinite linear;
  animation-timing-function: ease;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 30%;
  transform: translateY(-50%);
  z-index: 99999999999;
  width: 90px;
  height: 90px;
}
.container svg {
  width: 90px;
  height: 90px;
}
@keyframes anim {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes anim {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
