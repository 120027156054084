.container {
  background-color: rgb(255, 255, 255);
  color: rgb(69, 90, 100);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}
.container:hover {
  transform: scale(1.1, 1.1);
}
.container > svg {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.container h4 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0px;
  color: var(--secondary-color);
}
.container p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0px;
  color: var(--secondary-color);
  margin: 0px;
}
.container > i {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}
