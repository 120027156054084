.container {
  padding: 50px 60px 30px 60px;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
.header h1 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 0px;
  flex-grow: 1;
  color: var(--secondary-color);
}
.header button {
  display: inline-flex;
  justify-content: center;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  width: 180px;
  box-shadow: none;
  padding: 12px 24px;
}
.header button:hover {
  opacity: 0.8;
}
