.container {
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  background: var(--gradient-bg);
  color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.star {
  background-color: #ffffff;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4b72d7;
  font-size: 24px;
  margin-bottom: 20px;
}
.container h5 {
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.334;
  letter-spacing: 0px;
  margin-bottom: 10px;
}
.container p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0px;
  margin-bottom: 25px;
}
.container button {
  position: relative;
  outline: 0px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  padding: 16px 24px;
  color: var(--secondary-color);
  transition: 400ms all ease-in-out;
}
.container button:hover {
  opacity: 0.9;
}
