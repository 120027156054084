.container {
  padding: 50px 60px 30px 60px;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
.header h1 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 0px;
  flex-grow: 1;
  color: var(--secondary-color);
}
.card {
  background-color: rgb(255, 255, 255);
  color: var(--secondary-color);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: none;
  background-image: none;
  padding: 24px;
  margin-bottom: 30px;
  position: relative;
}
.card h4 {
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.334;
  letter-spacing: 0px;
  margin-bottom: 20px;
}
.card .field {
  margin-bottom: 20px;
}
.card .field:last-child {
  margin-bottom: 0px;
}
.input, select {
  width: 100%;
  border: 1px solid rgb(205, 208, 211);
  border-radius: 15px;
  padding: 18px 12px;
  outline: none;
  color: #423857b7;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
  transition: all 400ms ease-in-out;
}
.input:focus {
  border: 1px solid var(--primary-color);
}
.card .btnContainer {
  display: flex;
  width: 100%;
}
.btn {
  display: inline-flex;
  justify-content: center;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  border-radius: 16px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  box-shadow: none;
  padding: 12px 24px;
}
.card button:hover {
  opacity: 0.8;
}

.card .add {
  position: absolute;
  right: 24px;
  top: 24px;
}

.questionContainer {
  border: 1px solid rgb(205, 208, 211);
  border-radius: 10px;
  padding: 15px;
}
.questionContainer > button {
  width: fit-content;
}

.submittedQuestion {
  background-color: rgb(236, 239, 241);
  border-radius: 10px;
  padding: 15px 25px 15px 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submittedQuestion > p {
    font-weight: 600;
}
.submittedQuestion > p > span {
    margin-right: 5px;
}
.submittedQuestion > i {
  cursor: pointer;
}
