.container {
  padding: 50px 60px 30px 60px;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
.header h1 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 0px;
  flex-grow: 1;
  color: var(--secondary-color);
}
.header button {
  display: inline-flex;
  justify-content: center;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  width: 180px;
  box-shadow: none;
  padding: 12px 24px;
  margin-left: 10px;
}
.header button:hover {
  opacity: 0.8;
}

.card {
  background-color: rgb(255, 255, 255);
  color: var(--secondary-color);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  padding: 24px;
  margin-bottom: 30px;
  position: relative;
}
.card h4 {
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.334;
  letter-spacing: 0px;
  margin-bottom: 20px;
}

.card .field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.card .field:last-child {
  margin-bottom: 0px;
}
.card .field label {
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 5px;
}
.card .field p {
  font-size: 18px;
}
.card .field .option {
  margin-bottom: 10px;
  font-size: 16px;
}