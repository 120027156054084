.container {
  padding: 50px 60px 30px 60px;
  display: flex;
  justify-content: space-between;
}
.leftContainer {
  flex-basis: 33.3333%;
  flex-grow: 0;
  max-width: 33.3333%;
  margin-right: 16px;
}
.textContainer {
  margin-bottom: 10px;
}
.textContainer h1:nth-child(1) {
  margin: 0px 0px 0.35em;
  font-weight: 800;
  font-size: 2rem;
  line-height: 1.167;
  letter-spacing: 0px;
  color: var(--secondary-color);
}
.textContainer h1:nth-child(2) {
  margin: 0px 0px 24px;
  font-size: 2rem;
  line-height: 1.167;
  letter-spacing: 0px;
  font-weight: 300;
  color: var(--secondary-color);
}
.textContainer p {
  margin: 0px 0px 0px 0px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0px;
  color: rgb(96, 125, 139);
  max-width: 300px;
}
.textContainer svg {
  width: 300px;
  height: 306px;
}

.rightContainer {
  /* flex-basis: 66.6667%;
  flex-grow: 0;
  max-width: 66.6667%; */
  flex-basis: 64%;
  flex-grow: 0;
  max-width: 64%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}
.rightTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}