.container {
  padding: 50px 60px 30px 60px;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 30px;
}
.header h1 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.4;
  letter-spacing: 0px;
  flex-grow: 1;
  color: var(--secondary-color);
  max-width: 700px;
}
.field {
  width: 100%;
}
.field label {
  color: #423857b7;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4375em;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
}
.field input {
  margin-top: 10px;
  width: 100%;
  border: 1px solid rgb(205, 208, 211);
  border-radius: 15px;
  padding: 18px 12px;
  outline: none;
  color: #423857b7;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
  transition: all 400ms ease-in-out;
}
.quillContainer {
  margin-top: 10px;
}
:global(.ql-toolbar.ql-snow) {
  background-color: #ffffff;
  border: 1px solid rgb(205, 208, 211);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
:global(.ql-container.ql-snow) {
  background-color: #ffffff;
  border: 1px solid rgb(205, 208, 211);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.field input :focus {
  border: 1px solid var(--primary-color);
}
.header button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  width: 180px;
  height: 55px;
  box-shadow: none;
  padding: 12px 24px;
}
.header button:hover {
  opacity: 0.8;
}

.body {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: 0px;
  color: var(--secondary-color);
}
.body p {
  margin-bottom: 15px;
}
.body ul {
  margin-bottom: 15px;
}
.body q {
  font-style: italic;
  margin-bottom: 15px;
}
.body h1,
h2,
h3 {
  margin-bottom: 15px;
}
.submit {
  margin-top: 20px !important;
  display: inline-flex;
  justify-content: center;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  width: 100%;
  box-shadow: none;
  padding: 12px 24px;
}
.submit:hover {
  opacity: 0.8;
}
