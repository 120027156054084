.container {
  display: flex;
  height: 100vh;
}
.leftContainer {
  flex-basis: 58.3333%;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 58.3333%;
  background-image: url("../../assets/login/vector.svg");
  background-repeat: no-repeat;
  background-color: rgb(236, 239, 241);
  background-size: cover;
  background-position: center center;
}
.rightContainer {
  flex-basis: 41.6667%;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 41.6667%;
  background-color: rgb(255, 255, 255);
  color: rgb(69, 90, 100);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  box-sizing: border-box;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentRegister {
  margin-bottom: 40px;
}
.contentLogin {
  margin-bottom: 200px;
}
.content {
  max-width: 464px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
}
.content h1 {
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.334;
  letter-spacing: 0px;
  margin-top: 20px;
  color: var(--secondary-color);
}

.content form {
  width: 100%;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
}
.field {
  position: relative;
  margin-bottom: 25px;
}
.content form input {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0px;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.06);
  color: var(--secondary-color);
  border-radius: 16px;
  border: none;
  padding: 18px 10px;
  outline: none;
  position: relative;
  transition: 500ms all ease !important;
  border: 1px solid rgba(0, 0, 0, 0.06);
  font-family: "Poppins", sans-serif;
}
.content form input[type="password"]::placeholder {
  font-size: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.content form input:focus {
  border: 1px solid var(--primary-color);
}
.content form button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 15px 0px 30px 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  width: 100%;
  box-shadow: none;
  padding: 16px 24px;
  text-transform: none;
}
.content form button:hover {
  opacity: 0.8;
}
.small {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  color: var(--primary-color);
  align-self: center;
  cursor: pointer;
}
