.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999;
}
.container {
  background-color: #ffffff;
  border-radius: 5px;
  max-width: 680px;
  max-height: 700px;
  overflow-y: auto;
  width: 100%;
  padding: 60px 20px 30px 20px;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container .times {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 32px;
  cursor: pointer;
  color: #423857b7;
}
.container h2 {
  position: absolute;
  top: 12px;
  left: 20px;
  color: var(--secondary-color);
}
.field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}
.field label {
  margin-bottom: 10px;
  color: #423857b7;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
}
.field input {
  width: 100%;
  border: 1px solid rgb(205, 208, 211);
  border-radius: 10px;
  padding: 12px 10px;
  outline: none;
  color: #423857b7;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4375em;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
  transition: all 400ms ease-in-out;
}
.field .imgContainer {
  position: relative;
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.field .imgContainer img {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  opacity: 0.8;
  filter: brightness(70%);
}
.field .imgContainer i {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  top: 50%;
  width: fit-content;
  font-size: 30px;
  color: #ffffff;
}
.field .imgContainer input {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  top: 50%;
  width: 100px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
}
.container button {
  display: inline-flex;
  justify-content: center;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 10px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  width: 100%;
  box-shadow: none;
  padding: 12px 24px;
}
.container button:hover {
  opacity: 0.8;
}
