.container {
  background-color: rgb(255, 255, 255);
  color: rgb(69, 90, 100);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  align-items: center;
  width: 48%;
}
.container img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  margin-right: 15px;
}
.textContainer {
  display: flex;
  flex-direction: column;
}
.textContainer p {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0px;
  color: var(--secondary-color);
  margin-bottom: 0px;
}
.textContainer small {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.43;
  letter-spacing: 0px;
  color: rgb(96, 125, 139);
}
