.container {
  background-color: rgb(236, 239, 241);
  margin-left: 280px;
  padding: 30px 60px 20px 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.container > i {
  font-size: 22px;
  cursor: pointer;
  margin-right: 40px;
}
.container > svg {
  width: 42px;
  height: 42px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}
.container > svg:hover {
  background-color: rgba(255, 255, 255, 0.466);
}
.admin {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--secondary-color);
}
.admin svg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.admin p {
  margin: 0px 10px 0px 10px;
}
.adminBack {
  /* visibility: hidden; */
  opacity: 0.7;
  position: absolute;
  top: 60px;
  right: 70px;
  width: 210px;
  height: 210px;
  z-index: 1;
}
