@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
}

:root {
  --primary-color: #7a4bd7;
  --secondary-color: #423857;
  --gradient-bg: linear-gradient(135deg, #4b72d7 2.88%, #7a4bd7 100%);
}
.error {
  color: red;
}

.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
  width: 100%;
  z-index: 9999;
}

.react-datetime-picker__wrapper {
  border: none;
  width: 100%;
  border: 1px solid rgb(205, 208, 211) !important;
  border-radius: 15px;
  padding: 18px 12px;
}

.react-datetime-picker__wrapper input {
  border-radius: 5px;
}

.react-datetime-picker__wrapper span {
}

.react-datetime-picker__inputGroup select {
  width: fit-content;
}
