.container {
  padding: 50px 60px 30px 60px;
  display: flex;
}
.leftContainer {
  flex-basis: 33.3333%;
  flex-grow: 0;
  max-width: 33.3333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.placeholder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  margin-bottom: 24px;
  height: 160px;
  width: 160px;
}
.placeholder svg {
  width: 1em;
  height: 1em;
  display: inline-block;
  flex-shrink: 0;
  transition: fill 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 120px;
}
.leftContainer h3 {
  margin: 0px 0px 5px 0px;
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.235;
  letter-spacing: 0px;
  color: var(--secondary-color);
}
.leftContainer p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0px;
  margin: 0px;
  color: var(--secondary-color);
}
.rightContainer {
  flex-basis: 66.6667%;
  flex-grow: 0;
  max-width: 66.6667%;
  padding-left: 16px;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.step {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  position: relative;
  flex-shrink: 0;
  text-align: center;
  color: var(--secondary-color);
  border-radius: 50rem;
  padding: 10px 16px;
  background-color: transparent;
}
.step:hover {
  background-color: #ffffff;
}
.active {
  background-color: #ffffff;
}
.step:nth-child(2) {
  margin-left: 20px;
}
.step p {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  line-height: 1.25;
}
