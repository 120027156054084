.container {
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: auto;
  width: 210px;
  padding: 18px;
  position: absolute;
  top: 85px;
  right: 70px;
  border-radius: 5px;
  z-index: 7;
}
.container ul li {
  list-style: none;
  color: var(--secondary-color);
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}
.container ul li:last-child {
  margin-bottom: 0px;
}
.container ul li:hover {
  opacity: 0.8;
}
