.container {
  background-color: rgb(255, 255, 255);
  color: rgb(69, 90, 100);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  margin-bottom: 32px;
  max-width: 500px;
  width: 48%;
}
.header {
  display: flex;
  align-items: center;
  padding: 24px 24px 0px;
}
.header h4 {
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.334;
  letter-spacing: 0px;
  color: var(--secondary-color);
}
.body {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 24px;
}

.body ul {
  padding: 0px;
  margin: 0px 0px 20px 0px;
}
.body ul li {
  list-style: none;
  display: flex;
  align-items: center;
  color: var(--secondary-color);
  margin-bottom: 15px;
}
.body ul li:last-child {
  margin-bottom: 0px;
}
.body ul li h5 {
  white-space: nowrap;
  margin-right: 5px;
}
.body ul li small {
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.body button {
  position: relative;
  outline: 0px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  background: var(--gradient-bg);
  padding: 16px 24px;
  color: #ffffff;
  transition: 400ms all ease-in-out;
}
.body button:hover {
  opacity: 0.9;
}
