.container {
  width: 371px;
  height: 218px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  position: relative;
}
.container small {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 12px;
  color: var(--secondary-color);
}
.container h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: var(--secondary-color);
  margin-bottom: 10px;
}
.body {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.body p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: var(--secondary-color);
  margin-bottom: 8px;
}
.body strong {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}
.readMore {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  position: absolute;
  left: 20px;
  bottom: 20px;
  cursor: pointer;
}
.readMore i {
  margin-left: 5px;
}
.trash {
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  color: rgb(192, 5, 5);
  font-size: 14px;
}
