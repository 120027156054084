.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999;
}
.container {
  background-color: #ffffff;
  border-radius: 5px;
  max-width: 680px;
  width: 100%;
  padding: 30px 20px;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container .times {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 32px;
  cursor: pointer;
  color: #423857b7;
}
.container form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-height: 400px;
  overflow-y: auto;
}
.field label {
  color: #423857b7;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4375em;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}
.field input {
  width: 100%;
  border: 1px solid rgb(205, 208, 211);
  border-radius: 15px;
  padding: 14px 12px;
  outline: none;
  color: #423857b7;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4375em;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
  transition: all 400ms ease-in-out;
}
.container form>button {
  align-self: flex-end;
  display: inline-flex;
  justify-content: center;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  width: 150px;
  box-shadow: none;
  padding: 12px 24px;
}
.container form button:hover {
  opacity: 0.8;
}

.container :global(.ql-container.ql-snow) {
  height: 100% !important;
  min-height: 300px;
}