.container {
  width: 280px;
  background-color: rgb(255, 255, 255);
  color: rgb(69, 90, 100);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: 1200;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0px;
  border: none;
  padding: 20px;
}
.container .logoContainer {
  padding: 15px 15px 20px 15px;
}
.container ul {
}
.container ul li {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 5px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 30px 16px;
  border-radius: 16px;
  position: relative;
  color: var(--secondary-color);
}
.container ul li:hover {
  background-color: rgb(236, 239, 241);
}
.container ul .active {
  background-color: rgb(236, 239, 241);
}
.container ul li i {
  margin: 0px;
  position: absolute;
  left: 12px;
}
.container ul li svg {
  margin: 0px;
  position: absolute;
  left: 6px;
  width: 30px;
  height: 30px;
  background-color: rgb(236, 239, 241);
  padding: 5px;
  border-radius: 100%;
}
.container ul li p {
  margin: 0px;
  position: absolute;
  left: 55px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0px;
}
.container small {
  position: absolute;
  bottom: 30px;
  left: 30px;
  text-transform: capitalize;
  color: var(--primary-color);
}
