.container {
  background-color: rgb(255, 255, 255);
  color: var(--secondary-color);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  padding: 24px;
}
.container h4 {
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.334;
  letter-spacing: 0px;
  margin-bottom: 35px;
}
.container form .field {
  margin-bottom: 20px;
}
.container form input {
  width: 100%;
  border: 1px solid rgb(205, 208, 211);
  border-radius: 15px;
  padding: 18px 12px;
  outline: none;
  color: #423857b7;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0px;
  font-family: "Poppins", sans-serif;
  transition: all 400ms ease-in-out;
}
.container form input:focus {
  border: 1px solid var(--primary-color);
}
.container form .btnContainer {
  display: flex;
  width: 100%;
}
.container form button {
  display: inline-flex;
  justify-content: center;
  outline: 0px;
  border: 0px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0px;
  min-width: 64px;
  border-radius: 16px;
  transition: 500ms all ease;
  color: rgb(255, 255, 255);
  background: var(--gradient-bg);
  width: 150px;
  box-shadow: none;
  padding: 12px 24px;
}
.container form button:hover {
  opacity: 0.8;
}
