.container {
  padding: 50px 60px 30px 60px;
  display: flex;
  flex-direction: column;
}
.container h1 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 0px;
  flex-grow: 1;
  color: var(--secondary-color);
  margin-bottom: 60px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
}
.header p {
  flex-basis: 20%;
  max-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  font-weight: 500;
}
.user {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px 0px;
  margin-bottom: 18px;
}
.user div {
  flex-basis: 20%;
  flex-shrink: 1;
  max-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.user div p {
  color: var(--secondary-color);
  font-weight: 300;
  font-size: 14px;
}
.user div i {
  color: var(--secondary-color);
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.user div i:hover {
  transform: scale(1.2, 1.2);
  color: red;
}
.name {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  position: relative;
  padding-left: 30px;
}
.name img {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  margin-right: 10px;
}
.pending {
  background-color: #f8d7da;
  color: #721c24 !important;
  padding: 5px 10px;
  border-radius: 5px;
  width: 80px;
  text-align: center;
  text-transform: uppercase;
}
.live {
  width: 80px;
  text-align: center;
  background-color: #d4edda;
  color: #155724 !important;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
}
